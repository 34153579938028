body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Murecho", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  display: none;
}
/* ::-webkit-scrollbar {
  scrollbar-width: "thin";
} */

/* ::-webkit-scrollbar {
  width: 0.2em;
  height: 0.2em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  height: 0.2em;
} */

::-webkit-scrollbar-thumb {
  background-color: #222121;
  border-radius: 20px;
}

::-webkit-scrollbar:vertical {
  display: "none";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
